/**
 * Comprueba el tipo de campo en su formulario y habilita la selección de listas segun corresponda.
 */
function changeTipo() {
    var listaSelect = $("#IdLista.form-control.desactivable");
    var datasetSelect = $("#IdDatasetCampo.form-control.desactivable");
    var descripcionHolder = $("#Dsc");
    var contarCaracteres = descripcionHolder.parent().find(".contar-caracteres").show();

    var tipo = $("#Tipo").val();
    //Id de los tipos
    var LSS = "4";
    var LSM = "5";
    var DST = "17";
    var TXL = "18";
    //Desactivamos tod por defecto para luego activarlo segun sea necesario y no tener que desactivar en cada opción
    listaSelect.prop("disabled", true);
    listaSelect.siblings('a.btn').addClass("disabled");
    datasetSelect.prop("disabled", true);
    datasetSelect.siblings('a.btn').addClass("disabled");
    contarCaracteres.show();
    if (typeof CKEDITOR !== 'undefined') {
        let instance = CKEDITOR.instances["Dsc"];
        if (instance) {
            instance.destroy();
        }
    }

    if (tipo === LSS || tipo === LSM) {
        listaSelect.prop("disabled", false);
        listaSelect.siblings('a.btn').removeClass("disabled");
    } else if (tipo == DST) {
        datasetSelect.prop("disabled", false);
        datasetSelect.siblings('a.btn').removeClass("disabled");
    } else if (tipo === TXL) {
        if (typeof CKEDITOR !== 'undefined') {
            CKEDITOR.config.removePlugins = "variable";
            CKEDITOR.replace("Dsc");
        }
        contarCaracteres.hide();
    }
}

/**
 * Oculta la ayuda tras un icono que permite desplegarla.
 */
function hideHelp() {
    var label = $(".control-label, .tiene-ayuda");
    label.each(function (i, e) {
        var theLabel = $(e);
        var ayuda = theLabel.siblings(".help-block");
        if (ayuda.length === 0) {
            return;
        }
        var forElement = theLabel.attr("for") || Date.now() + Math.random();
        var info = $(`<span class='info' data-gi-campo='${forElement}'>&#8505; </span>`);
        info.click(function (e) {
            var element = $(e.target);
            var campo = element.attr("data-gi-campo");
            $(`.help-block[data-gi-campo="${campo}"]`).slideToggle("fast");
            //element.siblings(".help-block").slideToggle("fast");
            return false;
        });
        if (theLabel.hasClass("tiene-ayuda")) {
            theLabel.append(info);
        } else {
            theLabel.after(info);
        }
        ayuda.attr("data-gi-campo", forElement).hide();
    });
}

/**
 * Manejador de evento que se dispara al alternar entre enviar o no mensaje cuando se ejecuta una acción. Activa
 * @param {any} e Evento recibido
 */
function activarMensaje(e) {
    var input = $(e.target);
    var parent = input.closest(".form-group");
    if (input.prop("checked")) {
        parent.find("select, textarea").each(function () {
            $(this).removeAttr("disabled");
        });
    } else {
        parent.find("select, textarea").each(function () {
            $(this).attr("disabled", "disabled");
        });
    }
}

/**
 * Selecciona todos los checkboxes dentro de un contendor identificado por un id.
 * @param {string} id Identificador del contenedor.
 * @returns {bool} false
 */
function seleccionarTodoCheck(id) {
    $(`#${id} input[type=checkbox]`).prop("checked", true).change();
    return false;
}

/**
 * Deselecciona todos los checkboxes dentro de un contendor identificado por un id.
 * @param {string} id Identificador del contenedor.
 * @returns {bool} false
 */
function deseleccionarTodoCheck(id) {
    $(`#${id} input[type=checkbox]`).prop("checked", false).change();
    return false;
}

/**
 * Asigna a todos los selects de un contendor con el valor del primer select.
 * @param {string} id Identificador del contenedor.
 * @return {bool} false
 */
function AsignarTodo(id) {
    var combos = $(`#${id} select`);
    var base = $(combos[0]).val();
    combos.each(function (i, elem) {
        $(elem).val(base);
    });
    return false;
}

/**
 * Manejador del evento click que convierte un enlace en una petición POST. Transforma los parametros y ejecuta la petición contra la dirección.
 * Tambien busca un AntiForgeryToken para adjuntar a la petición.
 * @param {MouseEvent} e Evento de click
 * @returns {bool} false
 */
function makePost(e) {
    e.preventDefault();
    let url = $(e.currentTarget).attr("href");
    let form = $(document.createElement("form")).attr("action", url).attr("method", "post");
    //Crea un campo para enviar el antiForgeryToken
    let antiForgery = $(document.createElement("input")).attr("name", "__RequestVerificationToken").attr("value", $("input[name=__RequestVerificationToken]").attr("value"));
    $(form).append(antiForgery);
    $("body").append(form);
    $(form).submit();
    return false;
}

/**
 * Realiza la busqueda en la primera tabla de una página mediante un input con el id "buscarNombre".
 * Oculta los campos que no coincidan con el filtro.
 */
function searchTable() {
    let filter = document.getElementById("buscarNombre").value.toUpperCase();
    let tr = document.getElementsByTagName("table")[0].getElementsByTagName("tr");

    // Ocultar filas que no casan
    for (let i = 0; i < tr.length; i++) {
        let td = tr[i].getElementsByTagName("td")[0];
        if (td) {
            if ($(td).children().last().text().toUpperCase().includes(filter) && !$(td).parent().hasClass("filtered")) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }
}

/**
 * Ordena una tabla.
 * @param {JQuery} table Tabla a ordenar.
 * @param {number} index Indice de la columna por la que ordenar la tabla.
 */
function sortTable(table, index) {
    let sortedRows = $("tbody>tr").sort(elementSort);
    table.prop("data-asc", !table.prop("data-asc"));
    var th = $(table).find("th").removeClass("orderUp").removeClass("orderDown");
    if (!table.prop("data-asc")) {
        sortedRows = sortedRows.toArray().reverse();
        th.eq(index).addClass("orderDown");
    } else {
        th.eq(index).addClass("orderUp");
    }

    function elementSort(a, b) {
        let elem1 = $(a.getElementsByTagName("td")[index]).children().last().text().toUpperCase();
        let elem2 = $(b.getElementsByTagName("td")[index]).children().last().text().toUpperCase();
        return elem1 < elem2 ? -1 : elem1 > elem2;
    }
    $("tbody").append(sortedRows);
}

/**
 * Añade un blob con el contenido del ficher al "src" de un hermano con la clase "preview".
 * @param {Node} input Elemento input para el que previsualizar su contenido.
 */
function previewInput(input) {
    var preview = $(input).parent().siblings(".preview");
    //Le pone al src un blob generado con los archivos que contiene el input
    $(preview).attr("src", URL.createObjectURL(input.files[0]));
}

/**
 * Añade imagenes con el contenido de un "input" a un hermano con la clase "preview".
 * @param {Node} input Elemento input para el que previsualizar su contenido.
 */
function previewInputMultiple(input) {
    var preview = $(input).parent().siblings(".preview");
    preview.empty();
    for (var i in input.files) {
        var file = input.files[i];
        var img = $('<img class="preview-galeria" src="#" alt="Sin imagen" />');
        //Le pone al src un blob generado con uno de los archivos que contiene el input
        $(img).attr("src", URL.createObjectURL(file));
        $(preview).append(img);
    }
}

/**
 * Añade un icono de carga en un elemento del documento.
 * @param {Node} lugar Elemento en el que añadir el icono de carga.
 */
function añadirCargar(lugar) {
    $(lugar).append($('<div class="row"><div class="col-md-12 center-block"><span class="icono-cargar girar"></span>Cargando</div></div>'));
}

/**
 * Muestra un elemento con la clase "loader" o "loader-container"
 */
function mostrarLoader() {
    $('.loader, .loader-container').show();
}

/**
 * Manejador de eventos de cambio de canal en un mensaje. Alternan entre el editor WYSIWYG y un textarea, dependiendo del canal.
 * @param {Event} ev Evento de cambio de canal.
 */
function modificarCanal(ev) {
    let valoresConEditor = ["0"];
    if (valoresConEditor.indexOf(ev.target.value) < 0) {
        let instance = CKEDITOR.instances["Mensaje"];
        if (instance) {
            instance.destroy();
        }
    } else {
        CKEDITOR.replace("Mensaje");
    }
}

function añadirCookie(name, value, minutes = 1) {
    const expires = new Date(Date.now() + minutes * 60000).toUTCString();
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
}

//Eventos cuando el documento ready
$(document).ready(function () {
    changeTipo();

    //Ocultar la ayuda tras un icono
    hideHelp();

    /**Activar y desactivar selects con checkboxes*/
    $(".checkbox-select").change(function () {
        $(this).closest("div").find("select").prop("disabled", !$(this).prop("checked"));
    });
    $(".checkbox-select").trigger("change");

    $("th.sortable").click(function (e) {
        sortTable($(this).parents("table").first(), $("th").index($(this)));
    });

    //Previsualizar imagenes
    $(".image-preview").change(function () {
        previewInputMultiple(this);
    });

    //Previsualizar videos
    $(".galeria-preview").change(function () {
        previewInputMultiple(this);
    });

    //Previsualizar audios
    $(".audio-preview").change(function () {
        previewInput(this);
    });

    //Previsualizar videos
    $(".video-preview").change(function () {
        previewInput(this);
    });

    let textArea = $("textarea");
    if (textArea.attr("maxlength")) {
        textArea.addClass("track-length");
        textArea.wrap('<div class="well"></div>');
        textArea.parent().append(`<small class="contar-caracteres">Quedan <span class="carateres">${textArea.attr("maxlength")}</span> caracteres</small>`);
    }

    function trackLength() {
        let maxLength = parseInt($(this).attr("maxlength"));
        let length = $(this).val().length;
        length = maxLength - length;
        let span = $(this).parent().find("span.carateres");
        span.text(length);
        if (length < maxLength * 0.1) {
            span.addClass("text-danger");
        } else {
            span.removeClass("text-danger");
        }
    }

    $(".track-length").keyup(trackLength);

    $("#Canal").change(function (e) {
        let SMS = "1";
        let maxSMSLength = 160;
        let mensaje = $("#Mensaje");
        if ($(e.target).val() === SMS) {
            mensaje.attr("maxlength", maxSMSLength);
            mensaje.addClass("track-length");
            mensaje.parent().append('<small class="contar-caracteres">Quedan <span class="carateres">' + textArea.attr("maxlength") + "</span> caracteres</small>");
            mensaje.keyup(trackLength);
            mensaje.trigger("keyup");
        } else {
            mensaje.removeAttr("maxlength");
            mensaje.removeClass("track-length");
            mensaje.parent().find(".contar-caracteres").remove();
            mensaje.unbind("keyup", trackLength);
        }
    });

    $("#Canal").trigger("change");

    //Cerrar automaticamente las alertas marcadas para ello
    setTimeout(function () {
        $(".alert-autoclose").alert("close");
    }, 5 * 1000); // 5 segundos

    $(".mostrar-contraseña").click(function () {
        let passwordInput = $(this).parent().parent().find("input");
        if ($(this).attr("aria-pressed") !== "true") {
            passwordInput.prop("type", "text");
        } else {
            passwordInput.prop("type", "password");
        }
    });
});